const getEnvironmentName = () => {
    //we can read the host to determine our environment
    // var uri = new URI(window.location.href);

    // var hostname = uri.hostname().toLowerCase();

    // if (hostname === "localhost" || hostname === "127.0.0.1" || hostname.startsWith("local")) {
    //     return "local";
    // } else {
    //     //it must be
    //     return "production";
    // }

    return "production";
};

export const getConfig = () => {
    let environment = getEnvironmentName();

    let configObject = {};

    switch (environment) {
        default:
        case "local":
            configObject = {
                NEXT_PUBLIC_BASE_URL: `${process.env.NEXT_PUBLIC_BASE_URL}`,
            };
            return configObject;
        case "production":
            configObject = {
                NEXT_PUBLIC_BASE_URL: `${process.env.NEXT_PUBLIC_BASE_URL}`
            };

            return configObject;
    }
};