import { createSlice } from '@reduxjs/toolkit';

export const searchSlice = createSlice({
  name: 'search',
  initialState: {
    showMap: false,
  },
  reducers: {
    updateShowMap: (state, action) => {
      state.showMap = action.payload;
    },
  },
  extraReducers: (builder) => {
  },
});

export const { updateShowMap } = searchSlice.actions;

export default searchSlice.reducer;
