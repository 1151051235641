import { createSlice } from '@reduxjs/toolkit';
import { getCurrentUserPermissions, impersonate } from 'features/auth/authActions';

export const homeSlice = createSlice({
  name: 'home',
  initialState: {
    loading: true,
    theme: 'light',
    error: null,
    openNav: false,
  },
  reducers: {
    setTheme: (state, action) => {
      localStorage.setItem('theme', action.payload);
      state.theme = action.payload;
      document
        .querySelector('html')
        ?.setAttribute('data-theme', action.payload);
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    clearError: (state, action) => {
      state.error = null;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setOpenNav: (state, action) => {
      state.openNav = action.payload;
    },
  },
  extraReducers: (builder) => {
    // loadInitialData
    // builder.addCase(loadInitialData.pending, (state, { payload }) => {
    //   state.loading = true;
    // });
    builder.addCase(getCurrentUserPermissions.fulfilled, (state, { payload }) => {
      state.loading = false;
    });
    builder.addCase(impersonate.rejected, (state, { payload }) => {
      state.loading = false;
    });
    builder.addCase(impersonate.fulfilled, (state, { payload }) => {
      state.loading = false;
    });
  },
});

export const { setTheme, setError, clearError, setLoading, setOpenNav } = homeSlice.actions;

export default homeSlice.reducer;
