import { configureStore } from '@reduxjs/toolkit';
import authReducer from '../features/auth/authSlice';
import applicationReducer from '../features/applications/applicationSlice';
import dynamicTableReducer from '../features/dynamic_table/dynamicTableSlice';
import homeReducer from '../features/home/homeSlice';
import searchReducer from '../features/search/searchSlice';
import { api } from './services/api';
import { errorHandler } from './middeware/ErrorHandler';

export const makeStore = () => {
  return configureStore({
    reducer: {
      //RTK Query
      [api.reducerPath]: api.reducer,
      //Slices

      applications: applicationReducer,
      auth: authReducer,
      dynamicTable: dynamicTableReducer,
      home: homeReducer,
      search: searchReducer,
    },
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(errorHandler).concat(api.middleware),
  });
}
