import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import isEmpty from '../../utilities/isEmpty';
import { getConfig } from 'utilities/config';
import parseError from 'utilities/parseError';
import Cookies from 'universal-cookie';
import { setJwtToken } from 'utilities/setJwtToken';

const config = getConfig();


// First, create the thunk
export const userLogin = createAsyncThunk(
  'auth/login',
  async ({ values, setSubmitting }, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(
        `${config.NEXT_PUBLIC_BASE_URL}/auth/login`,
        values,
      );

      if (!isEmpty(data.jwtToken)) {
        setJwtToken(data.jwtToken);
      }

      if (!isEmpty(setSubmitting)) {
        setSubmitting(false);
      }

      return data;
    } catch (error) {
      if (!isEmpty(setSubmitting)) {
        setSubmitting(false);
      }

      return rejectWithValue(parseError(error));
    }
  },
);

export const userRegistration = createAsyncThunk(
  'auth/register',
  async ({ values, setSubmitting }, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(
        `${config.NEXT_PUBLIC_BASE_URL}/auth/register`,
        values,
      );

      if (!isEmpty(data.jwtToken)) {
        setJwtToken(data.jwtToken);
      }

      if (!isEmpty(setSubmitting)) {
        setSubmitting(false);
      }

      return data;
    } catch (error) {
      if (!isEmpty(setSubmitting)) {
        setSubmitting(false);
      }

      return rejectWithValue(parseError(error));
    }
  },
);

export const sendOtp = createAsyncThunk(
  'auth/sendOtp',
  async ({ values, setSubmitting }, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(
        `${config.NEXT_PUBLIC_BASE_URL}/auth/sendotp`,
        values,
      );


      if (!isEmpty(setSubmitting)) {
        setSubmitting(false);
      }

      return data;
    } catch (error) {
      if (!isEmpty(setSubmitting)) {
        setSubmitting(false);
      }

      return rejectWithValue(parseError(error));
    }
  },
);

export const verifyOtp = createAsyncThunk(
  'auth/verifyOtp',
  async ({ values, setSubmitting }, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(
        `${config.NEXT_PUBLIC_BASE_URL}/auth/verifyotp`,
        values,
      );

      if (!isEmpty(data.jwtToken)) {
        const cookies = new Cookies();

        var date = new Date();
        date.setDate(date.getDate() + 365);

        cookies.set('token', data.jwtToken, { path: '/', expires: date, secure: true });

        axios.defaults.headers.common['Authorization'] = 'Bearer ' + data.jwtToken;
      }

      if (!isEmpty(setSubmitting)) {
        setSubmitting(false);
      }

      return data;
    } catch (error) {
      if (!isEmpty(setSubmitting)) {
        setSubmitting(false);
      }

      return rejectWithValue(parseError(error));
    }
  },
);

export const getUsers = createAsyncThunk(
  'auth/getusers',
  async ([], { rejectWithValue }) => {
    try {
      const { data } = await axios.get(`${config.NEXT_PUBLIC_BASE_URL}/users`);

      return data;
    } catch (error) {
      return rejectWithValue(parseError(error));
    }
  },
);

export const impersonate = createAsyncThunk(
  'auth/impersonate',
  async ({ data }, { rejectWithValue }) => {
    return data;
  },
);

export const resetPassword = createAsyncThunk(
  'auth/resetPassword',
  async ({ values, setSubmitting, successCallback }, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(
        `${config.NEXT_PUBLIC_BASE_URL}/auth/resetpassword`,
        values,
      );

      if (!isEmpty(setSubmitting)) {
        setSubmitting(false);
      }

      if (!isEmpty(successCallback)) {
        successCallback();
      }

      return data;
    } catch (error) {
      if (!isEmpty(setSubmitting)) {
        setSubmitting(false);
      }

      return rejectWithValue(parseError(error));
    }
  },
);

export const confirmResetPassword = createAsyncThunk(
  'auth/confirmResetPassword',
  async ({ values, setSubmitting, successCallback }, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(
        `${config.NEXT_PUBLIC_BASE_URL}/auth/confirmresetpassword`,
        values,
      );

      if (!isEmpty(data.jwtToken)) {
        setJwtToken(data.jwtToken);
      }

      if (!isEmpty(setSubmitting)) {
        setSubmitting(false);
      }

      if (!isEmpty(successCallback)) {
        successCallback();
      }

      return data;
    } catch (error) {
      if (!isEmpty(setSubmitting)) {
        setSubmitting(false);
      }

      return rejectWithValue(parseError(error));
    }
  },
);

export const getCurrentUserPermissions = createAsyncThunk(
  'auth/loadAuthUserDetails',
  async ({ payload, callback }, { rejectWithValue }) => {
    try {
      const cookies = new Cookies();

      let token = cookies.get('token');

      if (!isEmpty(token)) {
        setJwtToken(token);
      }

      const { data } = await axios.get(`${config.NEXT_PUBLIC_BASE_URL}/auth/getuserpermissions`);

      return {
        userPermissions: data.userPermissions,
        jwtToken: token,
      };
    } catch (error) {
      return rejectWithValue(parseError(error));
    }
  },
);
